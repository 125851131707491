<script>
import SynopticEquipmentDataControlBase from "@/components/synoptic/synoptic-equipment-data-control-base.vue";

export default {
  name: "SynopticStatus",
  extends: SynopticEquipmentDataControlBase,
  computed: {
    isDirty() {
      return false;
    },
    defState() {
      const defState = this.control.synopticComponent?.default ?? "";
      return (this.control?.synopticComponent?.stateImages || []).find(
        (i) =>
          defState
            ? i.state == defState
            : String(i.state).toLowerCase() == "default" // legacy
      );
    },
    currentState() {
      let value;
      if (this.$utils.trim(this.expression) !== "") {
        value = this.evaluate();
      } else {
        value =
          typeof this.rawValue == "boolean"
            ? this.rawValue
              ? 1
              : 0
            : this.rawValue;
      }
      return (
        (this.items || []).find(
          (i) => String(i.state) !== "" && String(i.state) === String(value)
        ) ||
        this.defState ||
        null
      );
    },
    items() {
      return this.control?.synopticComponent?.stateImages || [];
    },
    globalLists() {
      return this.$root.config.references.text_lists || [];
    }
  }
};
</script>
